import React, {useEffect, useReducer, useState} from 'react';

const encrypt = async (secret, content) => {
    const encoder = new TextEncoder();

    const toBase64 = buffer =>
        btoa(String.fromCharCode(...new Uint8Array(buffer)));

    const randomHex = length =>
        [...window.crypto.getRandomValues(new Uint8Array(length))]
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');

    const iv = randomHex(8);
    const key = await window.crypto.subtle.importKey(
        'raw',
        encoder.encode(secret),
        {name: 'AES-CBC', length: 256},
        false,
        ['encrypt']
    );

    const encrypted = await window.crypto.subtle.encrypt(
        {name: 'AES-CBC', iv: encoder.encode(iv)},
        key,
        encoder.encode(content)
    );

    return encodeURIComponent(`${toBase64(encrypted)}:${iv}`);
};


const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

const IframePreview = () => {
    const [iframeSrc, setIframeSrc] = useState('');
    const [url, setUrl] = useState('');
    const [formData, setFormData] = useReducer(formReducer, {
        'partner-key': '',
        'partner-secret': '',
        'session': '',
        'user': '',
    });

    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    useEffect(() => {
        const {session, user} = formData;

        if (session.length === 0
            || formData['partner-key'].length === 0
            || formData['partner-secret'].length !== 32
        ) {
            setUrl('– invalid values –');
            return;
        }

        const payloadContent = {session};
        if (user) {
            payloadContent.user = user;
        }

        const setFullUrl = async () => {
            const payload = await encrypt(formData['partner-secret'], JSON.stringify(payloadContent));

            setUrl(`https://quickrisk.module.demo.pentech.hu?partner-key=${formData['partner-key']}&payload=${payload}`);
        };
        setFullUrl();
    }, [formData]);

    return <>
        <form>
            <p>
                <label htmlFor="partner-key">Partner key</label>
                <br/>
                <input
                    type="text"
                    id="partner-key"
                    name="partner-key"
                    onChange={handleChange}
                    required
                />
            </p>
            <p>
                <label htmlFor="partner-secret">Partner secret</label>
                <br/>
                <input
                    type="text"
                    id="partner-secret"
                    name="partner-secret"
                    onChange={handleChange}
                    minLength={'32'}
                    maxLength={'32'}
                    required
                />
                <span>&nbsp;(32 karakter)</span>
            </p>
            <p>
                <label htmlFor="session">Session id</label>
                <br/>
                <input
                    type="text"
                    id="session"
                    name="session"
                    onChange={handleChange}
                    minLength={'1'}
                    required
                />
            </p>
            <p>
                <label htmlFor="user">User id</label>
                <br/>
                <input
                    type="text"
                    id="user"
                    name="user"
                    onChange={handleChange}
                />
            </p>
            <p>
                src:
                <br/>
                <code style={{wordBreak: 'break-all'}}>{url}</code>
            </p>

            <p>
                <button
                    onClick={(event) => {
                        if (event.target.closest('form').checkValidity()) {
                            setIframeSrc(url);
                            event.preventDefault();
                        }
                    }}
                >
                    &lt;iframe&gt; betöltése
                </button>
            </p>
        </form>

        <div className="ratio-16-9">
            <iframe
                src={iframeSrc}
                title="iframe"
                width="10"
                height="300px"
            />
        </div>
    </>;
}

export default IframePreview;
