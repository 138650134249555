exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contributions-mdx": () => import("./../../../src/pages/contributions.mdx" /* webpackChunkName: "component---src-pages-contributions-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-pastpay-en-api-mdx": () => import("./../../../src/pages/pastpay-en/api.mdx" /* webpackChunkName: "component---src-pages-pastpay-en-api-mdx" */),
  "component---src-pages-pastpay-en-getting-started-mdx": () => import("./../../../src/pages/pastpay-en/getting-started.mdx" /* webpackChunkName: "component---src-pages-pastpay-en-getting-started-mdx" */),
  "component---src-pages-quickrisk-en-getting-started-mdx": () => import("./../../../src/pages/quickrisk-en/getting-started.mdx" /* webpackChunkName: "component---src-pages-quickrisk-en-getting-started-mdx" */),
  "component---src-pages-quickrisk-hu-embedding-mdx": () => import("./../../../src/pages/quickrisk-hu/embedding.mdx" /* webpackChunkName: "component---src-pages-quickrisk-hu-embedding-mdx" */),
  "component---src-pages-quickrisk-hu-external-connections-mdx": () => import("./../../../src/pages/quickrisk-hu/external-connections.mdx" /* webpackChunkName: "component---src-pages-quickrisk-hu-external-connections-mdx" */),
  "component---src-pages-quickrisk-hu-getting-started-mdx": () => import("./../../../src/pages/quickrisk-hu/getting-started.mdx" /* webpackChunkName: "component---src-pages-quickrisk-hu-getting-started-mdx" */),
  "component---src-pages-quickrisk-hu-iframe-preview-js": () => import("./../../../src/pages/quickrisk-hu/IframePreview.js" /* webpackChunkName: "component---src-pages-quickrisk-hu-iframe-preview-js" */),
  "component---src-pages-quickrisk-hu-keys-mdx": () => import("./../../../src/pages/quickrisk-hu/keys.mdx" /* webpackChunkName: "component---src-pages-quickrisk-hu-keys-mdx" */),
  "component---src-pages-quickrisk-hu-responsive-mdx": () => import("./../../../src/pages/quickrisk-hu/responsive.mdx" /* webpackChunkName: "component---src-pages-quickrisk-hu-responsive-mdx" */),
  "component---src-pages-quickrisk-hu-test-mdx": () => import("./../../../src/pages/quickrisk-hu/test.mdx" /* webpackChunkName: "component---src-pages-quickrisk-hu-test-mdx" */),
  "component---src-pages-quickrisk-hu-tools-mdx": () => import("./../../../src/pages/quickrisk-hu/tools.mdx" /* webpackChunkName: "component---src-pages-quickrisk-hu-tools-mdx" */),
  "component---src-pages-sp-api-hu-api-mdx": () => import("./../../../src/pages/sp-api-hu/api.mdx" /* webpackChunkName: "component---src-pages-sp-api-hu-api-mdx" */),
  "component---src-pages-sp-api-hu-getting-started-mdx": () => import("./../../../src/pages/sp-api-hu/getting-started.mdx" /* webpackChunkName: "component---src-pages-sp-api-hu-getting-started-mdx" */),
  "component---src-pages-sp-api-hu-workflow-mdx": () => import("./../../../src/pages/sp-api-hu/workflow.mdx" /* webpackChunkName: "component---src-pages-sp-api-hu-workflow-mdx" */)
}

